// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("js-cookie")
//= require country_state_select

const imagePath = (name) => images(name, true)
require("shopify_app")
import 'css/styles'
import * as Cookies from "js-cookie"
import 'jquery'
import 'popper.js'
import './direct_uploads'
import './dropdowns'
import './stripe_check'
import $ from 'jquery';
import './jquery-ui';
import "@hotwired/turbo-rails"
import './cuttr'
import 'bootstrap'
import './owl.carousel.min'
import './jquery.matchHeight'
import './country_state_select'
import 'controllers'
import './isotope.min'
import './lightbox'
import './ekko-lightbox'

global.$ = jQuery;

function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

const update_price = (line_item_object) => {
    var line_item = line_item_object.data('item-id');
    var data = {};

    data.product = $('#order_line_item_'+ line_item +'_printer_dropdown').val();
    data.option1 = $('#order_line_item_'+ line_item +'_options_sizes_dropdown_'+ line_item).val();  // size
    data.option2 = $('#order_line_item_'+ line_item +'_options_papers_dropdown_'+ line_item).val(); // paper
    data.size_type = $(`input:radio.size_type_radio_${line_item}:checked`).val();

    if (data.size_type == 'standard') {
        data.standard_size = $('#order_line_item_' + line_item + '_standard_size').val();
        $('#custom_width_'+ line_item).prop('readonly', true);
        $('#custom_height_'+ line_item).prop('readonly', true);
        $('#custom_width_'+ line_item).val('');
        $('#custom_height_'+ line_item).val('');
        delete data.custom_width
        delete data.custom_height
        delete data.custom_field

        if (data.standard_size == '' || data.standard_size == null) {
            delete data.standard_size
            alert('Please select standard size');
            return false;
        }
    }

    if (data.size_type == 'custom') {
        data.custom_field = line_item_object.attr('id').indexOf('height') != -1 ? 'height' : 'width';
        data.custom_width = $('#custom_width_'+ line_item).val();
        data.custom_height = $('#custom_height_'+ line_item).val();
        $('#custom_width_'+ line_item).prop('readonly', false);
        $('#custom_height_'+ line_item).prop('readonly', false);

        delete data.standard_size

        if ((data.custom_width == '' || data.custom_width == null) && (data.custom_height == '' || data.custom_width == null)) {
            delete data.custom_width
            delete data.custom_height
            delete data.custom_field
            $('#custom_width_'+ line_item).focus();
            return false;
        }
    }

    if (data.size_type == 'exact') {
        $('#custom_width_'+ line_item).val('');
        $('#custom_height_'+ line_item).val('');
        $('#custom_width_'+ line_item).prop('readonly', true);
        $('#custom_height_'+ line_item).prop('readonly', true);

        delete data.custom_width
        delete data.custom_height
        delete data.custom_field
        delete data.standard_size
    }

    data.is_with_border = $(`input:radio.is_with_border_${line_item}:checked`).val();

    $('#loader_' + line_item).removeClass('d-none');

    data.called_from = window.location.href.includes('products') ? 'products' : 'cart'

    $.ajax({
        type: "PUT",
        data: data,
        headers: {
            'Accept': 'text/vnd.turbo-stream.html',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // CSRF token for Rails
        },
        url: `/line_items/${line_item}/update_price`,
        success: function(response) {
            replaceResponse(response);
            updateOrderReadyTime();
        },
        error: function(xhr, status, error) {
            // Error handling
            console.error("Error:", status, error);
        },
        complete: function() {
            // Optional: any cleanup or UI changes after the request completes
            $('#loader_' + line_item).addClass('d-none');
        }
    });
}

export const replaceResponse = (response) => {

    // Create a temporary jQuery object for the response
    var $tempDiv = $('<div>').html(response);

    // Find the turbo-stream elements within the response
    $tempDiv.find('turbo-stream').each(function() {
        // Process each turbo-stream element
        var targetId = $(this).attr('target');
        var action = $(this).attr('action');
        var templateContent = $(this).find('template').html();

        console.dir(targetId);
        console.dir(action);

        if (templateContent) {
            var $target = $('#' + targetId);

            if ($target.length === 0) {
                console.warn("Target element not found for turbo-stream");
                return;
            }

            switch (action) {
                case "replace":
                    $target.html(templateContent);
                    break;
            }
        }
    });

    // Clear the temporary div
    $tempDiv = null;
}

function updateOrderReadyTime() {
    if($('#order-ready-time').length > 0) {
        $.ajax({
            type: "GET",
            url: '/orders/ready_time',
            success: function(response) {
                $('#order-ready-time').html(response);
            },
            error: function(xhr, status, error) {
                console.error("Error updating order ready time:", status, error);
            }
        });
    }
}

function disableTurboForLinksAndForms() {
    document.querySelectorAll('a, form').forEach(function(element){
        element.setAttribute('data-turbo', 'false');
    });
}

document.addEventListener('turbo:load', function() {
    new Swiper('#announcements-swiper', {
        autoplay: {
          delay: 5000,
        },
        loop: true,
    });

    disableTurboForLinksAndForms();
    
    // Quantity links +/-
    $(document).off('click', '.custom-ajax-link').on('click', '.custom-ajax-link', function(event) {
        event.preventDefault(); // Prevent the default link behavior
        event.stopImmediatePropagation();

        var url = $(this).attr('href');

        $.ajax({
            type: "PUT",
            url: url,
            data: { called_from: window.location.href.includes('products') ? 'products' : 'cart' },
            headers: {
                'Accept': 'text/vnd.turbo-stream.html',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                replaceResponse(response);
                updateOrderReadyTime();
            },
            error: function(xhr, status, error) {
                // Error handling
                console.error("Error:", status, error);
            }
        });
    });

    $(document).off('change', '.paper_dropdown, .size_type_radio, .is_with_border, .standard_size_dd').on('change', '.paper_dropdown, .size_type_radio, .is_with_border, .standard_size_dd', function() {
        update_price($(this));
    });

    $(document).off('blur', '.custom_fields').on('blur', '.custom_fields', function() {
        var currentText = $(this).val();
        var originalText = $(this).data('item-value');

        if(originalText != '' && originalText != '0.0' && originalText != '0' && currentText != '' && currentText != originalText) {
            update_price($(this));
        }
    });

    $(document).off('change', '.checkbox_upgrade').on('change', '.checkbox_upgrade', function() {
        var data = {};
        data.checked_upgrade_ids = $(`.checkbox_upgrade:checkbox:checked`).map(function(){
            return $(this).val();
        }).get();

        var product = $('#order_product_id').val();

        data.called_from = window.location.href.includes('products') ? 'products' : 'cart'

        $.ajax({
            type: "PUT",
            data: data,
            headers: {
                'Accept': 'text/vnd.turbo-stream.html',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // CSRF token for Rails
            },
            url: `/orders/${product}/use_upgrades`,
            success: function(response) {
                replaceResponse(response);
                updateOrderReadyTime();
            },
            error: function(xhr, status, error) {
                // Error handling
                console.error("Error:", status, error);
            }
        });
    });

    $(document).off('change', '#order_product_id').on('change', '#order_product_id', function () {
        var product_friendly_id = $(this).val();
        if (product_friendly_id) {
            $.ajax({
                type: "GET",
                data: { called_from: window.location.href.includes('products') ? 'products' : 'cart' },
                dataType: 'script',
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // CSRF token for Rails
                },
                url: `/cart/${product_friendly_id}`,
                success: function(data) {
                    // Change the URL without reloading the page
                    window.history.pushState({}, '', `/cart/${product_friendly_id}/${$('.paper_dropdown:first').val()}`);
                    updateOrderReadyTime();
                },
            });
        }
    });

    $(document).off('click', '.order__delete').on('click', '.order__delete', function(e){
        let del = $(this).siblings('.order__delete-link')[0]
        del.click();
    });

    var owl = $('.testimonial-section .owl-carousel');

    owl.owlCarousel({
        margin: 50,
        smartSpeed: 1000,
        autoplay:true,
        nav: false,
        dots: true,
        dotsEach: true,
        loop: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            991: {
                items: 3
            }
        },
    });

    $(document).off('change', '#saved_addresses_dropdown').on('change', '#saved_addresses_dropdown', function(){
        var address_id = $(this).val();

        $.ajax({
            type: 'GET',
            url: `/addresses/${address_id}`,
            dataType: 'script',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // CSRF token for Rails
            },
            success: function (result) {
                setTimeout(function(){
                    $('#address_note').val(" ");
                }, 100);
            },
            error: function(xhr, status, error) {
                // Error handling
                console.error("Error:", status, error);
            }
        });
    });

    $(document).off('keypress', '.number-field').on('keypress', '.number-field', function(event) {
        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });

    if (Cookies.get('firstVisit') !== 'true') {
        Cookies.set('firstVisit', 'true', { expires: 1000 });
        if($('#firstVisitModal').length > 0){
            $('#firstVisitModal').modal('show');
        }
    }

    if (Cookies.get('cookieModal') !== 'true') {
        Cookies.set('cookieModal', 'true', { expires: 1 });
        setTimeout(function() {
            // Show your modal here if needed
        }, 1500);
    } else {
        $('#cookiediv').hide();
    }

    var overflowX = $('.productcard>.cart').length <= 3 ? "hidden" : "auto";
    $('.productcard').css('overflow-x', overflowX);

    var navbar_menu = sessionStorage.getItem("nav-menu-state");
    $("#collapsibleNavbar").addClass(navbar_menu);

    $('#toggle-menu').click(function() {
        if (navbar_menu == null) {
            sessionStorage.setItem("nav-menu-state", "show");
        } else {
            sessionStorage.removeItem("nav-menu-state");
        }
    });

    // initProductAccordion();

    $('.paper__details').click(function(event){
        event.preventDefault();
        event.stopPropagation();
        console.log($(event.target.parentNode.childNodes[1]).find('.paper__videos'));

        $(event.target.parentElement).addClass('paper__item_active');
        $(event.target.parentNode.childNodes[3]).addClass('paper__desc_active');
        $(event.target.parentNode.childNodes[1]).find('.paper__videos').removeClass('paper__sizes_hidden');
        $(this).addClass('paper__details_active');
        $(event.target.nextElementSibling).toggleClass('paper__sizes_hidden paper__sizes_active');

        $(".paper__item").not($(event.target.parentElement)).removeClass("paper__item_active");
        $(".paper__desc").not($(event.target.parentNode.childNodes[3])).removeClass("paper__desc_active");
        console.log($(event.target.parentNode.childNodes[1]).data("paper_id"));
        $(".paper__videos").not($(event.target.parentNode.childNodes[1]).find('.paper__videos')).addClass("paper__sizes_hidden");
        $(".paper__details").not($(this)).removeClass("paper__details_active");
        $(".paper__sizes").not($(event.target.nextElementSibling)).addClass('paper__sizes_hidden');

    });

    $('.toggle-button').click(function(j) {
        $('.toggle-button').not(this).children('.rotate').removeClass("down");
        $(this).children('.rotate').toggleClass("down");
        var accordion = $(this).closest('.accordion-body').find('.body-content-accor');
        var contentText = $(this).closest('.accordion-body').find('.content-text');
        var fullText = contentText.innerHTML;
        $(this).closest('.accordion-list').find('.body-content-accor').not(accordion).slideUp();
        $(this).closest('.accordion-list').find('.content-text').not(contentText).removeClass('active');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).closest('.accordion-list').find('.toggle-button.active').removeClass('active');
            $(this).addClass('active');
        }
        accordion.stop(false, true).slideToggle();
        contentText.stop(false, true).toggleClass('active');
        j.preventDefault();
    });

    $(document).off('click', '.navbar-toggler').on('click', '.navbar-toggler', function(e) {
        e.preventDefault();
        $(this).toggleClass('navbar-toggler--active');
    });

    $(document).off('change', '#status').on('change', '#status', function (e) {
        $("#orders_search_form").submit();
    });

    $(document).off('click', '.navbar-toggler').on('click', '.navbar-toggler', function() {
        $("header").toggleClass("change_header_bg").toggleClass("main_header_bg");
    });

    $(document).off('click', '#btnEndStep1').on('click', '#btnEndStep1', function() {
        $("#step1").addClass('hideMe');
        $("#step2").removeClass('hideMe');
    });

    $(document).off('click', '#btnEndStep2').on('click', '#btnEndStep2', function() {
        $("#step2").addClass('hideMe');
        $("#step3").removeClass('hideMe');
    });

    $(document).off('click', '#btnEndStep3').on('click', '#btnEndStep3', function() {
        // Whatever your final validation and form submission requires
        $("#sampleModal").modal("hide");
    });

    $('.address-cart .data-list li .data-wrapper h3').matchHeight();

    setTimeout(function() {
        $('.flash-messages-section').fadeOut('1500');
    }, 3000);

    $('.toggle-edit-modal').click(function () {
        setTimeout(function() {
            $('#editModal').modal();
        }, 330);
    });

    $('.toggle-forgot-modal').click(function () {
        setTimeout(function() {
            $('#forgotModal').modal();
        }, 330);
    });

    $('.toggle-login-modal').click(function () {
        setTimeout(function() {
            $('#loginModal').modal();
        }, 330);
    });

    $('.toggle-accreate-modal').click(function () {
        setTimeout(function() {
            $('#accreateModal').modal();
        }, 330);
    });

    $('.toggle-resend-email-modal').click(function () {
        setTimeout(function() {
            $('#resendEmailModal').modal();
        }, 330);
    });

    $('.toggle-resend-unlock-modal').click(function () {
        setTimeout(function() {
            $('#resendUnlockModal').modal();
        }, 330);
    });

    var dropZone = $('#upload__container');

    $(document).off('drag dragstart dragend dragover dragenter dragleave drop', '#upload__container').on('drag dragstart dragend dragover dragenter dragleave drop', '#upload__container', function(e){
        e.preventDefault();
        e.stopPropagation();
    });

    $(document).on('dragover dragenter', '#upload__container', function() {
        dropZone.addClass('dragOn');
    });

    $(document).on('dragleave', '#upload__container', function(e) {
        let dx = e.pageX - dropZone.offset().left;
        let dy = e.pageY - dropZone.offset().top;
        if ((dx < 0) || (dx > dropZone.width()) || (dy < 0) || (dy > dropZone.height())) {
            dropZone.removeClass('dragOn');
        }
    });

    $(document).on('drop', '#upload__container', function(e) {
        dropZone.removeClass('dragOn');
        $("#file_input").prop("files", e.originalEvent.dataTransfer.files);
        document.getElementsByClassName('addcard__hiddenlink')[0].click();
    });

    $(document).off('change', '#file_input').on('change', '#file_input', function () {
        document.getElementsByClassName('addcard__hiddenlink')[0].click();
    });

});

disableTurboForLinksAndForms(); // Ensure it runs on initial load

function simulateMouseClick(targetNode) {
    function triggerMouseEvent(targetNode, eventType) {
        var clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent(eventType, true, true);
        targetNode.dispatchEvent(clickEvent);
    }
    ["mouseover", "mousedown", "mouseup", "click"].forEach(function(eventType) {
        triggerMouseEvent(targetNode, eventType);
    });
}
